// src/components/RecruitmentTree.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import Tree from 'react-d3-tree';

const RecruitmentTree = ({ userId }) => {
  const [treeData, setTreeData] = useState(null);

  // Función para construir la jerarquía de datos
  const buildTree = (users, currentUser) => {
    // Encuentra los usuarios que tienen el "Recruiter Code" igual al "Personal Code" del reclutador actual
    const children = users
      .filter(user => user['Recruiter Code'] === currentUser['Personal Code']) // Ajuste clave aquí
      .map(user => buildTree(users, user)); // Llamada recursiva para construir la jerarquía de cada hijo
    
    return {
      name: currentUser.Name,
      attributes: {
        Position: currentUser.Position,
        'Personal Code': currentUser['Personal Code'],
      },
      children,
    };
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'csv-data'));
        const users = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const currentUser = users.find(user => user.id === userId);
        if (currentUser) {
          const tree = buildTree(users, currentUser);
          setTreeData([tree]);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [userId]);

  if (!treeData) {
    return <div>Loading tree...</div>;
  }

  return (
    <div style={{ width: '100%', height: '500px' }}>
      <Tree data={treeData} orientation="vertical" />
    </div>
  );
};

export default RecruitmentTree;
