// src/components/DetailPage.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import RecruitmentTree from './RecruitmentTree';
import './DetailPageStyles.css';

const DetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [updatedFields, setUpdatedFields] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // Agregar un estado para controlar la visualización del árbol
  const [showTree, setShowTree] = useState(false);

  // Define los campos completos para todos los usuarios, incluidos licenciados y otros
  const allFieldsOrder = [
    "Name", "Position", "Type", "Personal Code", "Phone", "Birth Date",
    "Recruiter", "Recruiter Code", "Leader", "Leader Code", "Start Date", 
    "Spouse", "Email", "Home Address", "Business Address", 
    "Licencia","Deposito Directo", "Fast Star", "E&O", "Consumo Interno", "Producto", 
    "Orientacion 1", "Orientacion 2", "Orientacion 3", "Orientacion 4", "Boot Camp", 
    "Registro Aseguradoras", "PUNTOS ENERO", "PUNTOS FEBRERO", "PUNTOS MARZO", 
    "PUNTOS ABRIL", "PUNTOS MAYO", "PUNTOS JUNIO", "PUNTOS JULIO", "PUNTOS AGOSTO", 
    "PUNTOS SEPTIEMBRE", "PUNTOS OCTUBRE", "PUNTOS NOVIEMBRE", "PUNTOS DICIEMBRE", 
    "Fecha Vencimiento", "Fecha de examen", "Estado Examen", "Huellas", 
    "Aprobacion examen", "Fecha inicio", "Fecha fin", 
    "SA Promotion Progress (Recruits Personales 2 meses)", "(Business)2", "Promotion SA", 
    "MD Promotion Progress Puntos", "Agentes licenciados", "PROMOCIÓN MD", 
    "EMD Promotion Progress Puntos", "Agentes licenciados2", "Promoción EMD", 
    "CFT", "Notes", "Agent Code", "Columna5", "LICENCIA", "Date Licensed", 
    "Grand Openning","Net License", "TOTAL PERSONAL YTD", "TOTAL EQUIPO YTD", 
    "TOTAL PERSONAL 3 MESES", "TOTAL EQUIPO 3 MESES", "TOTAL PERSONAL 2 MESES", 
    "TOTAL EQUIPO 2 MESES",
    // Agrega cualquier otro campo adicional relevante para usuarios licenciados
  ];

  // Define los campos específicos para representatives de tipo estudiante
  const studentFieldsOrder = [
    "Name", "Position", "Type", "Personal Code", "Phone", "Birth Date",
    "Recruiter", "Recruiter Code", "Leader", "Leader Code", "Start Date", 
    "Spouse", "Phone", "Email", "Home Address", "Business Address", 
    "Licencia","Deposito Directo", "Fast Star", "E&O", "Consumo Interno", "Producto", 
    "Orientacion 1", "Orientacion 2",
  ];

  const estadosUSA = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", 
    "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", 
    "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", 
    "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", 
    "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", 
    "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", 
    "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", 
    "Rhode Island", "South Carolina", "South Dakota", "Tennessee", 
    "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", 
    "Wisconsin", "Wyoming"
  ];

   // Cargar detalles del usuario desde localStorage
   useEffect(() => {
    const storedDetails = localStorage.getItem('userDetails');
    if (storedDetails) {
      const parsedDetails = JSON.parse(storedDetails);
      setUserDetails(parsedDetails);
    } else {
      console.error('No se encontraron los detalles del usuario en el localStorage.');
      navigate('/');
    }
  }, [id, navigate]);

  const handleChange = (key, value) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
    setUpdatedFields((prevFields) => {
      if (!prevFields.includes(key)) {
        return [...prevFields, key];
      }
      return prevFields;
    });
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'csv-data', id));
      navigate('/');
    } catch (error) {
      console.error('Error al eliminar el documento:', error);
    }
  };

  const handleSave = async () => {
    try {
      await updateDoc(doc(db, 'csv-data', id), userDetails);
      closeSaveModal();
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 5000);
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
    }
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const openSaveModal = () => {
    setShowSaveModal(true);
  };

  const closeSaveModal = () => {
    setShowSaveModal(false);
  };

  // Determina qué campos mostrar basado en la posición y el tipo de usuario
  const fieldsOrder = 
    userDetails.Position?.toLowerCase() === 'representative' && userDetails.Type?.toLowerCase() === 'estudiante'
      ? studentFieldsOrder 
      : allFieldsOrder;

  // Filtrar el campo "Type" para usuarios que no son representatives
  const filteredFieldsOrder = userDetails.Position?.toLowerCase() !== 'representative'
    ? fieldsOrder.filter(field => field !== 'Type')
    : fieldsOrder;

  const handleExport = () => {
    const csvContent = [
      filteredFieldsOrder.join(','), // Encabezados
      filteredFieldsOrder.map(field => userDetails[field] || '').join(',') // Datos ordenados
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `user_details.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // Función para manejar la visualización del árbol
  const toggleTree = () => {
    setShowTree(prev => !prev);
  };
  const renderInputField = (key) => {
    const commonSelectOptions = ["Selecciona una opción", "Sí", "No"];
    switch (key) {
      case "Deposito Directo":
      case "Huellas":
      case "Aprobacion examen":
      case "Fast Star":
      case "Consumo Interno":
      case "Orientacion 1":
      case "Orientacion 2":
      case "Orientacion 3":
      case "Orientacion 4":
      case "E&O":
      case "Boot Camp":
      case "Registro Aseguradoras":
  
        return (
          <select 
            value={userDetails[key] || "Selecciona una opción"} 
            onChange={(e) => handleChange(key, e.target.value)}
          >
            {commonSelectOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        );
      case "Fecha de ingreso":
      case "Fecha Vencimiento":
      case "Fecha de examen":
        return (
          <input 
            type="date" 
            value={userDetails[key] || ''} 
            onChange={(e) => handleChange(key, e.target.value)} 
          />
        );
      case "Estado Examen":
        return (
          <select 
            value={userDetails[key] || ''} 
            onChange={(e) => handleChange(key, e.target.value)}
          >
            <option value="">Selecciona un estado</option>
            {estadosUSA.map(estado => (
              <option key={estado} value={estado}>{estado}</option>
            ))}
          </select>
        );
      case "Producto":
        return (
          <select 
            value={userDetails[key] || ''} 
            onChange={(e) => handleChange(key, e.target.value)}
          >
            <option value="">Selecciona un producto</option>
            {["IUL", "Término", "Anualidad", "Gastos finales"].map(producto => (
              <option key={producto} value={producto}>{producto}</option>
            ))}
          </select>
        );
      default:
        return (
          <input 
            type="text" 
            value={userDetails[key] || ''} 
            onChange={(e) => handleChange(key, e.target.value)} 
          />
        );
    }
  };

  return (
    <div className="detail-container">
      <h2>Detalles del Usuario</h2>
      {showSuccessMessage && (
        <div className="success-message">
          Se han actualizado los campos: {updatedFields.join(', ')}
        </div>
      )}
      <div className="button-floating-left">
        <button onClick={() => navigate('/')} className="back-button">Volver</button>
        <button onClick={handleExport} className="export-button">Exportar</button>
        <button onClick={toggleTree} className="export-button">Ver Cadena de Reclutamiento</button>
      </div>
      {showTree && <RecruitmentTree userId={id} />}
      <div className="button-floating-left">
        <button onClick={() => navigate('/')} className="back-button">Volver</button>
        <button onClick={handleExport} className="export-button">Exportar</button>
      </div>
      <div className="button-floating-right">
        <button onClick={openSaveModal} className="save-button">Guardar</button>
      </div>
      <table className="detail-table">
        <tbody>
          {filteredFieldsOrder.map((key, index) => (
            <tr key={`${key}-${index}`}>
              <th>{key}</th>
              <td>
                {renderInputField(key)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="button-group-bottom">
        <button onClick={openDeleteModal} className="delete-button">Eliminar</button>
      </div>

      {/* Modal para Confirmar Eliminación */}
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Confirmar Eliminación</h3>
            <p>¿Estás seguro de que deseas eliminar este usuario?</p>
            <button onClick={handleDelete} className="confirm-button">Sí, Eliminar</button>
            <button onClick={closeDeleteModal} className="cancel-button">Cancelar</button>
          </div>
        </div>
      )}

      {/* Modal para Confirmar Guardado */}
      {showSaveModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Confirmar Guardado</h3>
            <p>¿Estás seguro de que deseas guardar los cambios?</p>
            <button onClick={handleSave} className="confirm-button">Sí, Guardar</button>
            <button onClick={closeSaveModal} className="cancel-button">Cancelar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailPage;