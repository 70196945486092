// src/components/CsvUploader.js
import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { db } from '../firebase';
import { collection, addDoc, getDocs, setDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './TableStyles.css';

const CsvUploader = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filter, setFilter] = useState('');
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateUser, setDuplicateUser] = useState(null);
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [currentRepresentative, setCurrentRepresentative] = useState(null);
  const navigate = useNavigate();

  const fieldsOrder = [
    "Name", "Position", "Type", "Personal Code", "Phone", "Birth Date",
    "Recruiter", "Recruiter Code", "Leader", "Leader Code", "Start Date", 
    "Spouse", "Email", "Home Address", "Business Address", 
    "Licencia","Deposito Directo", "Fast Star", "E&O", "Consumo Interno", "Producto", 
    "Orientacion 1", "Orientacion 2", "Orientacion 3", "Orientacion 4", "Boot Camp", 
    "Registro Aseguradoras", "PUNTOS ENERO", "PUNTOS FEBRERO", "PUNTOS MARZO", 
    "PUNTOS ABRIL", "PUNTOS MAYO", "PUNTOS JUNIO", "PUNTOS JULIO", "PUNTOS AGOSTO", 
    "PUNTOS SEPTIEMBRE", "PUNTOS OCTUBRE", "PUNTOS NOVIEMBRE", "PUNTOS DICIEMBRE", 
    "Fecha Vencimiento", "Fecha de examen", "Estado Examen", "Huellas", 
    "Aprobacion examen", "Fecha inicio", "Fecha fin", 
    "SA Promotion Progress (Recruits Personales 2 meses)", "(Business)2", "Promotion SA", 
    "MD Promotion Progress Puntos", "Agentes licenciados", "PROMOCIÓN MD", 
    "EMD Promotion Progress Puntos", "Agentes licenciados2", "Promoción EMD", 
    "CFT", "Notes", "Agent Code", "Columna5", "LICENCIA", "Date Licensed", 
    "Grand Openning","Net License", "TOTAL PERSONAL YTD", "TOTAL EQUIPO YTD", 
    "TOTAL PERSONAL 3 MESES", "TOTAL EQUIPO 3 MESES", "TOTAL PERSONAL 2 MESES", 
    "TOTAL EQUIPO 2 MESES",
  ];
  const handleDuplicateDecision = (goToUser) => {
    if (goToUser) {
      console.log('Redirigiendo al usuario duplicado');
      navigate(`/details/${duplicateUser.id}`);
    }
    setShowDuplicateModal(false);
  };

  // Función para cargar todos los datos de usuarios desde Firebase
  const fetchAllUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'csv-data'));
      const users = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setData(users);
      setOriginalData(users);
    } catch (error) {
      console.error('Error al cargar los datos:', error);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  // Manejo del archivo CSV cargado
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      console.error("No se seleccionó ningún archivo.");
      return;
    }

    Papa.parse(file, {
      complete: async (results) => {
        try {
          const newRows = results.data.filter(row => row['Personal Code']); // Filtra filas válidas con código personal

          for (let row of newRows) {
            const duplicate = data.find(user => user['Personal Code'] === row['Personal Code']);
            if (duplicate) {
              setDuplicateUser(duplicate);
              setShowDuplicateModal(true); // Mostrar modal de usuario duplicado
              return;
            }

            if (row['Position'] && row['Position'].toLowerCase() === 'representative') {
              setCurrentRepresentative(row);
              setShowTypeModal(true); // Mostrar modal para usuarios representativos
              return;
            }

            // Agregar el usuario a Firebase si no es duplicado o representative
            const collectionRef = collection(db, 'csv-data');
            await addDoc(collectionRef, row);
            setData(prevData => [...prevData, row]);
            setOriginalData(prevData => [...prevData, row]); 
          }
        } catch (error) {
          console.error("Error al procesar el archivo:", error);
        }
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  // Función para manejar la selección del tipo de usuario representative
  const handleTypeSelection = async (type) => {
    if (currentRepresentative) {
      currentRepresentative.Type = type;

      try {
        // Generar un ID para el nuevo documento y guardar el usuario representative
        const newDocRef = doc(collection(db, 'csv-data'));
        await setDoc(newDocRef, currentRepresentative);
        
        // Actualizar el estado con el nuevo representative
        setData([...data, { id: newDocRef.id, ...currentRepresentative }]);
        setOriginalData([...originalData, { id: newDocRef.id, ...currentRepresentative }]);
      } catch (error) {
        console.error("Error al guardar el usuario representative:", error);
      }
    }
    setShowTypeModal(false);
  };

  // Función para cerrar el modal de tipo
  const handleCloseTypeModal = () => {
    setShowTypeModal(false);
  };

  // Manejar el cambio de selección del filtro
  const handleFilterChange = (value) => {
    setFilter(value);
  };

  // Aplicar el filtro y añadirlo a la lista de filtros aplicados con lógica "AND"
  const handleFilter = () => {
    if (filter) {
      // Añadir el filtro a la lista de filtros aplicados
      const updatedAppliedFilters = [...appliedFilters, filter];
      setAppliedFilters(updatedAppliedFilters);
      
      // Filtrar los datos aplicando la lógica "AND"
      const filteredData = originalData.filter(user => 
        updatedAppliedFilters.every(f => !user[f] || user[f].trim() === '')
      );

      setData(filteredData);
      setFilter(''); // Reiniciar el selector de filtro
    }
  };

  // Remover un filtro aplicado y actualizar los resultados
  const handleRemoveAppliedFilter = (index) => {
    const newAppliedFilters = appliedFilters.filter((_, i) => i !== index);
    setAppliedFilters(newAppliedFilters);

    // Actualizar los datos aplicando la lógica "AND" con los filtros restantes
    const filteredData = originalData.filter(user => 
      newAppliedFilters.every(f => !user[f] || user[f].trim() === '')
    );

    setData(filteredData);
  };

  const handleClearFilters = () => {
    setFilter('');
    setAppliedFilters([]);
    setData(originalData);
  };

  const handleExport = () => {
    const csvContent = [
      fieldsOrder.join(','), 
      ...data.map((user) => 
        fieldsOrder.map(field => user[field] || '').join(',')
      )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `filtered_users.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleViewMore = (row) => {
    localStorage.setItem('userDetails', JSON.stringify(row));
    navigate(`/details/${row.id}`);
  };

  const areFiltersApplied = appliedFilters.length > 0;

  return (
    <div className="csv-table-container">
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        className="csv-upload-input"
      />

      <div className="filter-container">
        <select 
          value={filter} 
          onChange={(e) => handleFilterChange(e.target.value)}
        >
          <option value="">Seleccionar Campo</option>
          {fieldsOrder.map((field, idx) => (
            <option key={`${field}-${idx}`} value={field}>{field}</option>
          ))}
        </select>
        <button onClick={handleFilter} disabled={!filter}>Aplicar Filtros</button>
      </div>

      {/* Mostrar tabs de filtros aplicados */}
      {areFiltersApplied && (
        <div className="applied-filters-container">
          {appliedFilters.map((filter, index) => (
            <div key={index} className="applied-filter-tab">
              {filter}
              <button onClick={() => handleRemoveAppliedFilter(index)}>x</button>
            </div>
          ))}
        </div>
      )}

      {/* Texto explicativo como nota debajo de los filtros */}
      <p className="filter-tooltip">
        Este filtro mostrará únicamente los registros donde los campos seleccionados estén vacíos. 
        Selecciona un campo y aplica el filtro para visualizar las entradas correspondientes.
      </p>

      <button onClick={handleExport} className="export-button">Exportar Usuarios</button>

      {data.length > 0 && (
        <table className="csv-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Position</th>
              <th>Código Personal</th>
              <th>Recruiter</th>
              <th>Código Recruiter</th>
              <th>Leader</th>
              <th>Código Leader</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={row.id || index}>
                <td>{row.Name || ''}</td>
                <td>{row.Position || ''}</td>
                <td>{row['Personal Code'] || ''}</td>
                <td>{row.Recruiter || ''}</td>
                <td>{row['Recruiter Code'] || ''}</td>
                <td>{row.Leader || ''}</td>
                <td>{row['Leader Code'] || ''}</td>
                <td>{row.Phone || ''}</td>
                <td>{row.Email || ''}</td>
                <td>
                  <button onClick={() => handleViewMore(row)}>Editar</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal para manejar usuario duplicado */}
      {showDuplicateModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Usuario Duplicado</h3>
            <p>El usuario con el código {duplicateUser['Personal Code']} ya existe.</p>
            <button onClick={() => handleDuplicateDecision(true)}>Ver Usuario</button>
            <button onClick={() => handleDuplicateDecision(false)}>Ignorar</button>
          </div>
        </div>
      )}

      {/* Modal para manejar usuarios representative */}
      {showTypeModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Tipo de Usuario Representative</h3>
            <p>Selecciona el tipo para {currentRepresentative.Name}:</p>
            <button onClick={() => handleTypeSelection('Estudiante')}>Estudiante</button>
            <button onClick={() => handleTypeSelection('Licenciado')}>Licenciado</button>
            <button onClick={handleCloseTypeModal}>Cancelar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CsvUploader;
