// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CsvUploader from './components/CsvUploader';
import DetailPage from './components/DetailPage';
import RecruitmentTree from './components/RecruitmentTree'; // Importa el nuevo componente

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CsvUploader />} />
        <Route path="/details/:id" element={<DetailPage />} />
        <Route path="/recruitment-tree/:id" element={<RecruitmentTree />} /> {/* Nueva ruta */}
      </Routes>
    </Router>
  );
}

export default App;
