import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC0YTnLInKyUIAVAvEYIhAg6HrLXq-Hxow",
  authDomain: "biancareal.firebaseapp.com",
  projectId: "biancareal",
  storageBucket: "biancareal.appspot.com",
  messagingSenderId: "567516637806",
  appId: "1:567516637806:web:b2e56e998f02aab6cb147f"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app); // Inicializar autenticación

export { db, auth };